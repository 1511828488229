import { createId, numberToCommaSeperatedValue } from "./helpers";

/* Account Manager - Client Details - Show User Management Table */
export const generateClientDetailsShowUserTableItems = (userListItems, setUserResetPasswordEmail, setApprovalMessage, setShowApprovalPopup) => {
  const createShowUserRowLeft = (tempItemId, firstname, lastname) => {
    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#firstname`,
          'type': 'default',
          'text': firstname,
          'stretch': false
        },
        {
          'id': `${tempItemId}#lastname`,
          'type': 'default',
          'text': lastname,
          'stretch': false
        }
      ]
    };
  }

  const createShowUserRowRight = (tempItemId, email) => {
    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#firstname`,
          'type': 'link-button',
          'text': 'Send a reset password email',
          'stretch': false,
          'onClick': () => {
            setUserResetPasswordEmail(email);
            setApprovalMessage(<span>You're about to send a password reset email to <strong>{email}</strong>, do you want to proceed?</span>);
            setShowApprovalPopup(true);
          }
        }
      ]
    };
  }
  
  const createShowUserRowMiddle = (tempItemId, email, department, jobTitle, userType, lastLogin) => {
    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${tempItemId}#email`,
          'type': 'default',
          'text': email,
          'stretch': false
        },
        {
          'id': `${tempItemId}#department`,
          'type': 'default',
          'text': department,
          'stretch': false
        },
        {
          'id': `${tempItemId}#job-title`,
          'type': 'default',
          'text': jobTitle,
          'stretch': false
        },
        {
          'id': `${tempItemId}#user-type`,
          'type': 'default',
          'text': userType,
          'stretch': false
        },
        {
          'id': `${tempItemId}#last-login`,
          'type': 'default',
          'text': lastLogin,
          'stretch': false
        },
        {
          'id': `${tempItemId}#filler-right`,
          'type': 'empty-cell',
          'stretch': false
        },
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'user-list-header-left',
            'columns': [
              {'text': 'NAME', 'stretch': false},
              {'text': 'SURNAME', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [128, 128],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [128, 128],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableRightItems = () => {
    const rightItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'user-list-header-right',
            'columns': [
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [196],
          'columnPaddings': [
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [196],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return rightItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': '', 'stretch': false},
              {'text': 'EMAIL', 'stretch': false},
              {'text': 'DEPARTMENT', 'stretch': false},
              {'text': 'JOB TITLE', 'stretch': false},
              {'text': 'TYPE', 'stretch': false},
              {'text': 'LAST LOGIN', 'stretch': false},
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            304, 196, 228, 196, 128, 228, 196
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            304, 196, 228, 196, 128, 228, 196
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems(),
    'right': createTableRightItems()
  };

  userListItems.forEach(item => {
    const tempItemId = `client-user-${createId(4)}`;
    tableItems['left']['body']['items'].push(
      createShowUserRowLeft(tempItemId, item['firstname'], item['lastname'])
    );
    tableItems['right']['body']['items'].push(
      createShowUserRowRight(tempItemId, item['email'])
    );
    tableItems['middle']['body']['items'].push(
      createShowUserRowMiddle(tempItemId, item['email'], item['department'], item['job_title'], item['user_type'], item['last_login_time'] ? new Date(item['last_login_time']).toISOString() : '-')
    );
  });

  return tableItems;
}


/* Account Manager - Client Details - Edit User Management Table */
export const generateClientDetailsEditUserTableItems = (addNewUserRowHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-user-list-add-button',
            'text': 'Add User',
            'onClick': addNewUserRowHandler,
            'leftIcon': 'plus'
          },
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'NAME', 'stretch': false},
              {'text': 'SURNAME', 'stretch': false},
              {'text': 'EMAIL', 'stretch': true},
              {'text': 'DEPARTMENT', 'stretch': true},
              {'text': 'JOB TITLE', 'stretch': true},
              {'text': 'TYPE', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            128, 128, 172, 172, 128, 128, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            128, 128, 172, 172, 128, 128, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


/* Account Manager - Client Details - Show Proxy Advisors Table */
export const generateClientDetailsShowProxyAdvisorTableItems = (clientType, proxyAdvisorItems) => {
  const createShowProxyAdvisorRow = (proxyAdvisorName) => {
    const tempItemId = `client-proxy-advisor-${createId(4)}`;
    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#proxy-advisor-name`,
          'type': 'default',
          'text': proxyAdvisorName,
          'stretch': true
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': `PROXY ADVISOR / RATING NAME (${proxyAdvisorItems.length})`, 'stretch': true}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            248
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            248
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShowProxyAdvisorRow(item['proxy_advisor_name'])
    );
  });

  return tableItems;
}


/* Account Manager - Client Details - Edit Proxy Advisors Table */
export const generateClientDetailsEditProxyAdvisorTableItems = (addNewProxyAdvisorRowHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'proxy-advisor-list-add-button',
            'text': 'Add Proxy Advisor / Rating',
            'onClick': addNewProxyAdvisorRowHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'PROXY ADVISOR / RATING NAME', 'stretch': false},
              {'text': '', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            420, 0, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            420, 0, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


/* Account Manager - Client Details - Show Investor List Table */
export const generateClientDetailsShowInvestorListTableItems = (clientType, investorListItems, totalNumOfShares) => {
  const createInvestorRow = (item) => {
    const tempItemId = `client-investor-list-${createId(4)}`;
    const investorName = item['name'] !== null ? item['name'] : '';
    const country = item['country'] !== null ? item['country'] : '';
    
    let numberOfShares = '-';
    let iscPercentage = '-';
    let lastMeetingVotes = '-';
    if(clientType === 'Corporate') {
      numberOfShares = item['num_of_shares'] !== null ? parseInt(item['num_of_shares']) : '';
      iscPercentage = numberOfShares !== '' ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
      lastMeetingVotes = item['last_general_meeting_votes'] !== null ? item['last_general_meeting_votes'] : '';
    }
    
    const isCustom = item['is_custom'];

    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#investor-name`,
          'type': 'text-with-chip',
          'text': investorName,
          'has_custom_chip': isCustom,
          'stretch': true
        },
        {
          'id': `${tempItemId}#country`,
          'type': 'default',
          'text': country,
          'stretch': true
        },
        {
          'id': `${tempItemId}#number-of-shares`,
          'type': 'default',
          'text': numberToCommaSeperatedValue(numberOfShares),
          'stretch': true
        },
        {
          'id': `${tempItemId}#isc-percentage`,
          'type': 'default',
          'text': iscPercentage,
          'stretch': true
        },
        {
          'id': `${tempItemId}#last-meeting-votes`,
          'type': 'default',
          'text': lastMeetingVotes,
          'stretch': true
        },
        {
          'id': `${tempItemId}#filler`,
          'type': 'default',
          'text': '',
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    let totalCalculatedNumOfShares = '';
    if(clientType === 'Corporate') {
      totalCalculatedNumOfShares = investorListItems.reduce((acc, curr) => acc + parseInt(curr['num_of_shares']), 0);
    }
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': `INVESTOR NAME (${investorListItems.length})`, 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'text': (clientType === 'Corporate') ? 
                      `# OF SHARES (${numberToCommaSeperatedValue(totalCalculatedNumOfShares)})` : 
                      `# OF SHARES`, 'stretch': true},
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true},
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorListItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorRow(item)
    );
  });

  return tableItems;
}


/* Account Manager - Client Details - Edit Investor List Table */
export const generateClientDetailsEditInvestorListTableItems = (addNewInvestorListRowHandler, removeAllInvestorsClickHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-investor-list-add-button',
            'text': 'Add Investor',
            'onClick': addNewInvestorListRowHandler,
            'leftIcon': 'plus'
          },
          {
            'id': 'client-investor-list-delete-all-button',
            'text': 'Delete ALL Investors',
            'onClick': removeAllInvestorsClickHandler,
            'type': 'icon-button',
            'icon': 'delete',
            'isVisible': true
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'INVESTOR NAME', 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'id': 'client-investor-list-num-of-shares', 'text': '# OF SHARES', 'stretch': true},
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}

import { Fragment, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { accountManagerMenuItems, clientMenuItems } from "../../../utils/constants";
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getAuthUserRole, getAuthUserType, getClientAvailableModules, isViewMode } from "../../../utils/auth";
import { fetchClientBasicProfileRequest } from "../../../requests/client-requests";
import { getUserProfileRequest, updateUserProfilePictureRequest } from "../../../requests/user-requests";
import { sendUpdateInvestorListFormRequest, uploadInvestorListCSVFileRequest } from "../../../requests/client-help-requests";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";

import SideNavigationBar from "../../../components/UI/SideNavigationBar/SideNavigationBar";

import UserDetailsSection from "./UserDetailsSection/UserDetailsSection";
import PasswordSettingSection from "./PasswordSettingSection/PasswordSettingSection";
import InvestorListSettingSection from "./InvestorListSettingSection/InvestorListSettingSection";
import SubscriptionDetailsSection from "./SubscriptionDetailsSection/SubscriptionDetailsSection";

import SquareWellProfilePicture from '../../../assets/other/squarewell-profile-picture.png';
import styles from './UserProfilePage.module.css';

const UserProfilePage = (props) => {

  const clientId = getAuthClientId();
  const clientName = getAuthClientName();
  const clientAvailableModules = getClientAvailableModules();
  const userRole = getAuthUserRole();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();
  
  const userType = getAuthUserType();
  const isAccountManagerViewMode = isViewMode();
  const isViewOnly = (userType === 'View Only') || isAccountManagerViewMode;
  const isPrimaryEditor = userType === 'Primary Editor';

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [userProfile, setUserProfile] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const [isPasswordChangeSelected, setIsPasswordChangeSelected] = useState(false);

  useEffect(() => {
    if (userRole === 'Client') {
      sendRequest(
        fetchClientBasicProfileRequest(clientId),
        (data) => {
          setClientProfilePictureUrl(data['profile_picture_url']);
        }
      );
    }
    else {
      setClientProfilePictureUrl(SquareWellProfilePicture);
    }

    sendRequest(
      getUserProfileRequest(),
      (data) => {
        setUserProfile(data);
        setClientDetails({ 'name': clientName });
        setUserDetails(data['user']);
        setSubscriptionDetails(data['subscription_details']);
      }
    );
  }, [sendRequest, clientId, clientName, userRole]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const changePasswordClickHandler = () => {
    setIsPasswordChangeSelected(prevState => {
      return !prevState;
    });
  }

  const uploadInvestorListCSVFileHandler = (attachmentData, setCSVAttachment, setIsSendInvestorListButtonDisabled) => {
    sendRequest(
      uploadInvestorListCSVFileRequest(clientId, attachmentData),
      (data) => { 
        setCSVAttachment(data);
        setIsSendInvestorListButtonDisabled(false);
      }
    );
  }

  const sendUpdatedInvestorListClickHandler = (investorListFormData, setShowInvestorListUpdateModal, setShowInvestorListUpdateRequestSentInfo) => {
    sendRequest(
      sendUpdateInvestorListFormRequest(clientId, investorListFormData),
      (data) => { 
        setShowInvestorListUpdateModal(false);
        setShowInvestorListUpdateRequestSentInfo(true);
      }
    );
  }

  const uploadUserProfilePictureHandler = (profilePictureData, setProfilePictureUrl) => {
    sendRequest(
      updateUserProfilePictureRequest(profilePictureData),
      (data) => {
        localStorage.setItem('userProfilePictureUrl', data['profile_picture_url']);
        setProfilePictureUrl(data['profile_picture_url']);
      }
    );
  }

  return (
    <div className={styles['page']}>
      <SideNavigationBar
        menuItems={userRole === 'Account Manager' ? accountManagerMenuItems : clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='profile'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div 
        id='page-right-container'
        className={`${styles['container']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>Account Settings</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['page-body']}>
          {userProfile && !isPasswordChangeSelected &&
            <UserDetailsSection 
              clientDetails={clientDetails}
              userDetails={userDetails}
              user={{profilePictureUrl: userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}}
              onProfilePictureUploadClick={uploadUserProfilePictureHandler}
              isViewOnly={isViewOnly}
              isPrimaryEditor={isPrimaryEditor}
            />
          }

          <PasswordSettingSection onChangePasswordClick={changePasswordClickHandler} />

          {userProfile && userDetails['user_role'] === 'Client' && !isPasswordChangeSelected && !isViewOnly && isPrimaryEditor &&
            <Fragment>
              <InvestorListSettingSection
                onInvestorListCSVFileUploadClick={uploadInvestorListCSVFileHandler}
                onSendUpdatedInvestorListClick={sendUpdatedInvestorListClickHandler}
              />
              <SubscriptionDetailsSection 
                subscriptionDetails={subscriptionDetails}
                clientAvailableModules={clientAvailableModules}
              />
            </Fragment>
          }

        { userProfile && userDetails['user_role'] === 'Client' && !isPasswordChangeSelected &&
          <div className={styles['disclamier-note-container']}>
            <div className={styles['disclamier-note-content']}>
              <p>Disclaimer. AQTION, a SquareWell Partners brand, and any third-party providers or licensors do not guarantee or warrant the correctness, accuracy, completeness, reliability, timeliness, availability, merchantability or fitness for a particular purpose of the information provided in the AQTION platform. The information provided is for informational purposes and do not constitute legal, investment or professional advice. SquareWell Partners and any third-party providers or licensors are not liable for the data, data structure, charts, graphs, literature, links included in the AQTION platform, or any decision or consequence based on use of the foregoing.</p>
            </div>
          </div>
        }
        </div>
      </div>
    </div>
  );
}

export default UserProfilePage;
